import { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { LocationMarkerSolid } from "@graywolfai/react-heroicons";

import { AppThunk } from "../store";
import { RootState } from "../rootReducer";
import { Page } from "./Page";

interface MatchData {
  createdAt: number;
  map: string;
  matchTime: number;
  source?: string;
  status: string;
  youtubeUrl?: string;
  url: string;
}
export const matchesStateSlice = createSlice({
  name: "settingsState",
  initialState: {
    isLoading: false,
    dataLoaded: false,
    matches: [] as MatchData[],
  },
  reducers: {
    setIsLoading: (state, action) => {
      if (!action.payload) {
        state.dataLoaded = true;
      }
      state.isLoading = action.payload;
    },
    addMatch: (state, action) => {
      state.matches.push(action.payload);
    },
  },
});
const db = firebase.firestore();
const loadMatches = (): AppThunk => async (dispatch) => {
  const userQuerySnapshot = await db
    .collection("users")
    .where("uid", "==", firebase.auth().currentUser?.uid)
    .get();

  userQuerySnapshot.forEach(async (userDoc) => {
    dispatch(matchesStateSlice.actions.setIsLoading(true));
    const matchesQuerySnapshot = await db
      .collection("matches")
      .where("user", "==", userDoc.ref)
      .orderBy("createdAt", "desc")
      .limit(10)
      .get();
    matchesQuerySnapshot.forEach((matchDoc) => {
      const matchData = matchDoc.data();

      dispatch(
        matchesStateSlice.actions.addMatch({
          createdAt: matchData.createdAt.toMillis(),
          map: matchData.map,
          matchTime: matchData.matchTime.toMillis(),
          status: matchData.status,
          source: matchData.source ?? "steam",
          youtubeUrl: matchData.youtube_url,
          url: matchData.url,
        })
      );
    });
    dispatch(matchesStateSlice.actions.setIsLoading(false));
    return;
  });
};

export const Home = () => {
  const dispatch = useDispatch();
  const matchesState = useSelector((state: RootState) => state.matchesState);
  const { dataLoaded, matches } = matchesState;

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(loadMatches());
    }
  }, [dispatch, dataLoaded]);
  return (
    <Page currentPage="home">
      <div className="px-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-extrabold text-gray-900">Home</h1>
        <div className="bg-white shadow overflow-hidden sm:rounded-md mt-5">
          <ul className="divide-y divide-gray-200">
            {matches.map((match) => (
              <li key={match.matchTime}>
                <a
                  href={
                    match.status === "Uploaded" ? match.youtubeUrl : match.url
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="block hover:bg-gray-50"
                >
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        {match.source === "steam"
                          ? "Matchmaking game"
                          : "FACEIT game"}{" "}
                        at {new Date(match.matchTime).toLocaleString()}
                      </p>
                      <div className="ml-2 flex-shrink-0 flex">
                        <p
                          className={
                            "px-2 inline-flex text-xs leading-5 font-semibold rounded-full " +
                            (match.status === "Error"
                              ? "bg-red-700 text-white"
                              : match.status === "Pending"
                              ? "bg-yellow-100 text-yellow-800"
                              : "bg-green-100 text-green-800")
                          }
                        >
                          {match.status}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        {match.map ? (
                          <>
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                              <LocationMarkerSolid className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                              {match.map}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        {/* Heroicon name: solid/calendar */}
                        <svg
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>
                          <time dateTime={new Date(match.createdAt).toString()}>
                            {new Date(match.createdAt).toLocaleString()}
                          </time>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Page>
  );
};
