import { combineReducers } from "@reduxjs/toolkit";

import { authStateSlice } from "./components/Auth";
import { settingsStateSlice } from "./components/Settings";
import { matchesStateSlice } from "./components/Home";

const rootReducer = combineReducers({
  authState: authStateSlice.reducer,
  settingsState: settingsStateSlice.reducer,
  matchesState: matchesStateSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
