import React, { FunctionComponent } from "react";
import { UserSolid, CogSolid, CodeSolid } from "@graywolfai/react-heroicons";
import { Menu, Transition } from "@headlessui/react";

interface SteamAccount {
  steamId: string;
  steamIdKey: string;
  knownCode: string;
}

export interface YoutubeAccount {
  playlist: string;
}

export interface FaceitAccount {
  name: string;
  id: string;
  picture: string;
  email: string;
}
interface LinkedAccountsProps {
  steamAccounts?: SteamAccount[];
  youtubeAccount?: YoutubeAccount;
  faceitAccount?: FaceitAccount;
}

const EnabledYoutubeLink = () => (
  <Menu.Item>
    {({ active }) => (
      <a
        href="#support"
        className={`${
          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
        } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
      >
        YouTube
      </a>
    )}
  </Menu.Item>
);
const DisabledYoutubeLink = () => (
  <Menu.Item
    as="span"
    disabled
    className="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 cursor-not-allowed opacity-50"
  >
    YouTube
  </Menu.Item>
);
const EnabledFaceitLink = () => (
  <Menu.Item>
    {({ active }) => (
      <button
        onClick={(e) => {
          const FACEIT = (window as any).FACEIT;
          FACEIT.loginWithFaceit();
          e.preventDefault();
        }}
        className={`${
          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
        } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
      >
        FACEIT
      </button>
    )}
  </Menu.Item>
);
const DisabledFaceitLink = () => (
  <Menu.Item
    as="span"
    disabled
    className="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 cursor-not-allowed opacity-50"
  >
    FACEIT
  </Menu.Item>
);

export const LinkedAccounts: FunctionComponent<LinkedAccountsProps> = (
  props
) => {
  const { steamAccounts, faceitAccount, youtubeAccount } = props;
  const hasFaceitAccount =
    faceitAccount && Object.keys(faceitAccount).length > 0;
  const hasYoutubeAccount =
    youtubeAccount && Object.keys(youtubeAccount).length > 0;
  return (
    <>
      <div className="flex items-center flex-row-reverse pt-10">
        <div className="relative inline-block text-left">
          <Menu>
            {({ open }) => (
              <>
                <span className="rounded-md shadow-sm">
                  <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-indigo-600 border border-gray-300 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 active:bg-gray-50 active:text-gray-800">
                    <span>Link Account</span>
                    <svg
                      className="w-5 h-5 ml-2 -mr-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Menu.Button>
                </span>

                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#account-settings"
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                          >
                            Steam
                          </a>
                        )}
                      </Menu.Item>
                      {hasFaceitAccount ? (
                        <DisabledFaceitLink />
                      ) : (
                        <EnabledFaceitLink />
                      )}
                      {hasYoutubeAccount ? (
                        <DisabledYoutubeLink />
                      ) : (
                        <EnabledYoutubeLink />
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-md mt-5">
        <ul className="divide-y divide-gray-200">
          {steamAccounts?.map((steamAccount) => (
            <li key={`steam-${steamAccount.steamId}`}>
              <a
                href={`http://steamcommunity.com/profiles/${steamAccount.steamId}`}
                target="_blank"
                rel="noreferrer"
                className="block hover:bg-gray-50"
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-indigo-600 truncate">
                      Steam
                    </p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Steam
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <UserSolid className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        {steamAccount.steamId}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                        <CogSolid className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        {steamAccount.steamIdKey}
                      </p>
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <CodeSolid className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                      {steamAccount.knownCode}
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ))}
          {hasFaceitAccount ? (
            <li>
              <a
                href={`https://www.faceit.com/en/players/${faceitAccount?.name}`}
                target="_blank"
                rel="noreferrer"
                className="block hover:bg-gray-50"
              >
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-medium text-indigo-600 truncate">
                      {faceitAccount?.name}
                    </p>
                    <div className="ml-2 flex-shrink-0 flex">
                      <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        FACEIT
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <p className="flex items-center text-sm text-gray-500">
                        <UserSolid className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
                        {faceitAccount?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          ) : null}
        </ul>
      </div>
    </>
  );
};
