import React, { FunctionComponent } from "react";

import {
  CogOutline,
  HomeOutline,
  LogoutOutline,
} from "@graywolfai/react-heroicons";

import { GlobalMenuLink } from "./GlobalMenuLink";

interface PageProps {
  currentPage?: "home" | "settings";
}

export const Page: FunctionComponent<PageProps> = (props) => {
  return (
    <div className="h-screen bg-white overflow-hidden flex">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <div className="md:hidden">
        <div className="fixed inset-0 z-40 flex">
          {/*
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      */}
          <div className="fixed inset-0" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
          {/*
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      */}
          <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
            <div className="absolute top-0 right-0 -mr-14 p-1">
              <button className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:bg-gray-600">
                {/* Heroicon name: outline/x */}
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span className="sr-only">Close sidebar</span>
              </button>
            </div>
            <div className="flex-shrink-0 px-4 flex items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/easywire-logo-purple-600-mark-gray-900-text.svg"
                alt="Easywire"
              />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="h-full flex flex-col">
                <div className="space-y-1">
                  <GlobalMenuLink
                    link="/"
                    title="Home"
                    icon={<HomeOutline />}
                    isCurrentPage={props.currentPage === "home"}
                    ariaCurrent={false}
                  />
                  <GlobalMenuLink
                    link="/settings"
                    title="Settings"
                    icon={<CogOutline />}
                    isCurrentPage={props.currentPage === "settings"}
                    ariaCurrent={false}
                  />
                </div>
                <div className="mt-auto pt-10 space-y-1">
                  <GlobalMenuLink
                    link="/signOut"
                    title="Logout"
                    icon={<LogoutOutline />}
                    isCurrentPage={false}
                    ariaCurrent={false}
                  />
                </div>
              </nav>
            </div>
          </div>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="w-64 flex flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <nav className="bg-gray-50 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
            <div className="flex-shrink-0 px-4 flex items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/easywire-logo-purple-600-mark-gray-900-text.svg"
                alt="Easywire"
              />
            </div>
            <div className="flex-grow mt-5 flex flex-col">
              <div className="flex-1 space-y-1">
                <GlobalMenuLink
                  link="/"
                  title="Home"
                  icon={<HomeOutline />}
                  isCurrentPage={props.currentPage === "home"}
                  ariaCurrent={props.currentPage === "home"}
                />
                <GlobalMenuLink
                  link="/settings"
                  title="Settings"
                  icon={<CogOutline />}
                  isCurrentPage={props.currentPage === "settings"}
                  ariaCurrent={props.currentPage === "settings"}
                />
              </div>
            </div>
            <div className="flex-shrink-0 block w-full">
              <GlobalMenuLink
                link="/signOut"
                title="Logout"
                icon={<LogoutOutline />}
                isCurrentPage={false}
                ariaCurrent={false}
              />
            </div>
          </nav>
        </div>
      </div>

      {/* Content area */}
      <div className="flex-1 flex flex-col">
        <div className="w-full max-w-4xl mx-auto md:px-8 xl:px-0">
          <div className="relative z-10 flex-shrink-0 h-16 bg-white flex">
            <button className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 md:hidden">
              <span className="sr-only">Open sidebar</span>
              {/* Heroicon name: outline/menu-alt-2 */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </button>
            <div className="flex-1 flex justify-between px-4 md:px-0">
              <div className="flex-1 flex"></div>
              <div className="ml-4 flex items-center md:ml-6">
                <button className="bg-white rounded-full p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                  {/* Heroicon name: outline/bell */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                  <span className="sr-only">View notifications</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <main
          className="flex-1 overflow-y-auto focus:outline-none"
          tabIndex={0}
        >
          <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
            <div className="pt-10 pb-16">{props.children}</div>
          </div>
        </main>
      </div>
    </div>
  );
};
