import firebase from "firebase/app";
import "firebase/firestore";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Switch,
  Redirect,
  Route,
  useHistory,
  useRouteMatch,
  useParams,
} from "react-router-dom";

import { AppThunk } from "../store";
import { Page } from "./Page";
import {
  LinkedAccounts,
  YoutubeAccount,
  FaceitAccount,
} from "./LinkedAccounts";
import { RootState } from "../rootReducer";
import React, { FunctionComponent, useEffect } from "react";

const db = firebase.firestore();

export const settingsStateSlice = createSlice({
  name: "settingsState",
  initialState: {
    isLoading: false,
    dataLoaded: false,
    name: "",
    linkedAccounts: {
      steamAccounts: [],
      faceitAccount: {} as FaceitAccount,
      youtubeAccount: {} as YoutubeAccount,
    },
  },
  reducers: {
    setIsLoading: (state, action) => {
      if (!action.payload) {
        state.dataLoaded = true;
      }
      state.isLoading = action.payload;
    },
    setData: (state, action) => {
      state.name = action.payload.name;
      state.linkedAccounts.steamAccounts = action.payload.steam;
      state.linkedAccounts.youtubeAccount = action.payload.youtube;
      state.linkedAccounts.faceitAccount = action.payload.faceit;
    },
    updateFaceitAccount: (state, action) => {
      state.linkedAccounts.faceitAccount = action.payload;
    },
  },
});

export const updateFaceitData = (
  faceitAccountData: FaceitAccount
): AppThunk => async (dispatch) => {
  const querySnapshot = await db
    .collection("users")
    .where("uid", "==", firebase.auth().currentUser?.uid)
    .get();
  querySnapshot.forEach(async (doc) => {
    await doc.ref.update("faceit", faceitAccountData);
    dispatch(settingsStateSlice.actions.updateFaceitAccount(faceitAccountData));
    return;
  });
};

export const loadData = (): AppThunk => async (dispatch) => {
  dispatch(settingsStateSlice.actions.setIsLoading(true));
  const querySnapshot = await db
    .collection("users")
    .where("uid", "==", firebase.auth().currentUser?.uid)
    .get();
  dispatch(settingsStateSlice.actions.setIsLoading(false));
  querySnapshot.forEach((doc) => {
    dispatch(settingsStateSlice.actions.setData(doc.data()));
  });
};

interface GeneralProps {
  name: string;
  email: string;
}
const General: FunctionComponent<GeneralProps> = (props) => (
  <React.Fragment>
    <div className="mt-10 divide-y divide-gray-200">
      <div className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Profile</h3>
        <p className="max-w-2xl text-sm text-gray-500">
          This information will be displayed publicly so be careful what you
          share.
        </p>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Name</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{props.name}</span>
              <span className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{props.email}</span>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div className="mt-10 divide-y divide-gray-200">
      <div className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Account</h3>
        <p className="max-w-2xl text-sm text-gray-500">
          Manage how information is displayed on your account.
        </p>
      </div>
      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Language</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">English</span>
              <span className="ml-4 flex-shrink-0">
                <button
                  type="button"
                  className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt className="text-sm font-medium text-gray-500">Date format</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">DD-MM-YYYY</span>
              <span className="ml-4 flex-shrink-0 flex items-start space-x-4">
                <button
                  type="button"
                  className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Update
                </button>
                <span className="text-gray-300" aria-hidden="true">
                  |
                </span>
                <button
                  type="button"
                  className="bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Remove
                </button>
              </span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt
              className="text-sm font-medium text-gray-500"
              id="timezone-option-label"
            >
              Automatic timezone
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {/* Enabled: "bg-purple-600", Not Enabled: "bg-gray-200" */}
              <button
                type="button"
                className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto"
                aria-pressed="true"
                aria-labelledby="timezone-option-label"
              >
                <span className="sr-only">Use setting</span>
                {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                <span
                  aria-hidden="true"
                  className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                ></span>
              </button>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt
              className="text-sm font-medium text-gray-500"
              id="auto-update-option-label"
            >
              Auto-update applicant data
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {/* Enabled: "bg-purple-600", Not Enabled: "bg-gray-200" */}
              <button
                type="button"
                className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-auto"
                aria-pressed="false"
                aria-labelledby="auto-update-option-label"
              >
                <span className="sr-only">Use setting</span>
                {/* Enabled: "translate-x-5", Not Enabled: "translate-x-0" */}
                <span
                  aria-hidden="true"
                  className="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                ></span>
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </React.Fragment>
);

const Password = () => (
  <React.Fragment>
    <div className="mt-10 divide-y divide-gray-200">
      <div className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Password
        </h3>
        <p className="max-w-2xl text-sm text-gray-500">Change your password</p>
      </div>
    </div>
  </React.Fragment>
);

interface TabProps {
  link: string;
  title: string;
  isCurrentTab: boolean;
  isFirstTab: boolean;
}
const Tab: FunctionComponent<TabProps> = (props) => (
  <Link
    to={props.link}
    className={
      (props.isCurrentTab
        ? "border-purple-500 text-purple-600 "
        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 ") +
      (props.isFirstTab ? " " : "ml-8 ") +
      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
    }
  >
    {props.title}
  </Link>
);

interface SettingsParams {
  topic: string;
}
export const Settings = () => {
  const currentUser = firebase.auth().currentUser;

  const history = useHistory();
  const { path } = useRouteMatch();
  const { topic } = useParams<SettingsParams>();
  const dispatch = useDispatch();
  const settings = useSelector((state: RootState) => state.settingsState);
  const { dataLoaded } = settings;

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(loadData());
    }
  }, [dispatch, dataLoaded]);

  return (
    <Page currentPage="settings">
      <div className="px-4 sm:px-6 md:px-0">
        <h1 className="text-3xl font-extrabold text-gray-900">Settings</h1>
      </div>
      <div className="px-4 sm:px-6 md:px-0">
        <div className="py-6">
          {/* Tabs */}
          <div className="lg:hidden">
            <label htmlFor="selected-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="selected-tab"
              name="selected-tab"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
              value={topic}
              onChange={(e) => {
                history.push(`/settings/${e.target.value}`);
              }}
            >
              <option value="general">General</option>
              <option value="accounts">Linked Accounts</option>
              <option value="password">Password</option>
            </select>
          </div>
          <div className="hidden lg:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex">
                <Tab
                  link="/settings/general"
                  title="General"
                  isCurrentTab={topic === "general"}
                  isFirstTab={true}
                />

                <Tab
                  link="/settings/accounts"
                  title="Linked Accounts"
                  isCurrentTab={topic === "accounts"}
                  isFirstTab={false}
                />

                <Tab
                  link="/settings/password"
                  title="Password"
                  isCurrentTab={topic === "password"}
                  isFirstTab={false}
                />
              </nav>
            </div>
          </div>
          <Switch>
            <Route exact path="/settings">
              <Redirect to={`/settings/general`} />
            </Route>
            <Route path={path}>
              {(() => {
                switch (topic) {
                  case "general":
                    return (
                      <General
                        name={settings.name}
                        email={
                          currentUser && currentUser.email
                            ? currentUser.email
                            : ""
                        }
                      />
                    );
                  case "password":
                    return <Password />;
                  case "accounts":
                    return (
                      <LinkedAccounts
                        steamAccounts={settings.linkedAccounts.steamAccounts}
                        youtubeAccount={settings.linkedAccounts.youtubeAccount}
                        faceitAccount={settings.linkedAccounts.faceitAccount}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </Route>
          </Switch>
        </div>
      </div>
    </Page>
  );
};
