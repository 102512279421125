import React from "react";

import { configureStore } from "@reduxjs/toolkit";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";

import rootReducer from "./rootReducer";
import { Home } from "./components/Home";
import { Settings, updateFaceitData } from "./components/Settings";
import { Auth, signOut } from "./components/Auth";
import "./App.css";

const store = configureStore({
  reducer: rootReducer,
});

const SignOut = () => {
  signOut();
  return <Redirect to="/" />;
};

const FaceitCallback = () => {
  return <>Faceit Callback</>;
};

const FACEIT = (window as any).FACEIT;
function faceitCallback(response: any) {
  if (response.isIdTokenValid === true) {
    const faceitUser = FACEIT.getUserInfo().value;
    store.dispatch(
      updateFaceitData({
        name: faceitUser.nickname,
        id: faceitUser.guid,
        picture: faceitUser.picture,
        email: faceitUser.email,
      })
    );
    return;
  }
  console.error("The id token is not valid, something went wrong");
}
FACEIT.init(
  {
    client_id: process.env.REACT_APP_FACEIT_CLIENT_ID,
    response_type: "token",
    redirect_popup: true,
  },
  faceitCallback
);

function App() {
  return (
    <ReduxProvider store={store}>
      <Auth>
        <Router>
          <Switch>
            <Route path="/signOut">
              <SignOut />
            </Route>
            <Route path="/settings/:topic?">
              <Settings />
            </Route>
            <Route path="/callback/faceit">
              <FaceitCallback />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </Auth>
    </ReduxProvider>
  );
}

export default App;
