import React, { FunctionComponent, ReactElement } from "react";

import { Link } from "react-router-dom";

interface GlobalMenuLinkProps {
  link: string;
  title: string;
  icon?: ReactElement;
  isCurrentPage: boolean;
  ariaCurrent: boolean;
}
export const GlobalMenuLink: FunctionComponent<GlobalMenuLinkProps> = (
  props
) => (
  <Link
    to={props.link}
    className={
      (props.isCurrentPage
        ? "bg-purple-50 border-purple-600 text-purple-600 "
        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 ") +
      "group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
    }
    aria-current={props.isCurrentPage && props.ariaCurrent ? "page" : "false"}
  >
    {props.icon ? (
      <span
        className={
          (props.isCurrentPage
            ? "text-purple-500 "
            : "text-gray-400 group-hover:text-gray-500 ") + "mr-3 h-6 w-6"
        }
      >
        {props.icon}
      </span>
    ) : null}
    {props.title}
  </Link>
);
